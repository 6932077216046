import { MarketsGrid } from "./MarketsGrid";
import mainLogo from "./jll_logo_large.png";
import "./App.modules.css";
import Header from "./Header";

export function App(){
    return(
        <div>
        <Header/>
        <div className="Logo">
            <img  src={mainLogo} alt="JLL Logo"/>
        </div>
        <MarketsGrid/>
      </div>
    )
}
