import markets from "./markets_domains.json";
import { Text } from 'react-native';
import styles from "./MarketsGrid.module.css";

export function MarketsGrid(){
    const keys = Object.keys(markets);
    const values = Object.values(markets);

    const openURL = (url) => {
        window.location.href = url;
    };

    return (
        <main className={styles.marketsSquare}>
            <div className={styles.headerText}>Property Search</div>
            <div className={styles.descriptionText} >We offer a wide range of properties for sale and lease around the globe. Whether you're looking for office or retail, industrial or hotels, we'll help you find the right property to meet your needs. Select a location to start your search.</div>
            <ul className={styles.marketsGrid}>
                {keys.map((key, index) => (
                    <li className={styles.marketButton} key={key}>
                        <Text
                            style={{ fontSize: 16, fontWeight: 'bold', color: 'rgb(119, 119, 119)' }}
                            onPress={() => openURL(values[index])}
                        >
                            {key}
                        </Text>
                    </li>
                ))}
            </ul>
        </main>
    );
}
