import React from 'react';
import './Header.modules.css';

const Header = () => {
  return (
    <header className="header">
      <div className="left-link">
        <a href="http://www.jll.com/">← jll.com</a>
      </div>
      <div className="right-links">
        <a href="http://www.jll.com/research">Research</a>
        <a href="http://www.jll.com/about">About</a>
      </div>
    </header>
  );
};

export default Header;
